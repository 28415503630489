import { BaseSyntheticEvent, useEffect, useState } from 'react'
import { TextInputWithLabel } from '@/src/common/components/elements/Forms/TextInputWithLabel'
import { Button, StyledH6 } from './StyledComponents'
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { firebaseAuth } from '@/config/firebaseClientConfig'
import * as yup from 'yup'
import { FormErrorDisplay } from '@/src/modules/build-a-card/FormErrorDisplay'
import { CODE, NEW_LOGIN, NUMBER, PHONE_SIGN_IN_BTN_ID } from './LoginConstants'
import AuthCode from 'react-auth-code-input'
import { useLoginContext } from './LoginContext'
import { inputHasError } from '@/src/common/utilities/inputHasError'

const phoneNumberSchema = yup.object({
    phoneNumber: yup
        .string()
        .trim()
        .required('Required')
        .length(14, 'Must be a valid US phone number'),
})

const verificationCodeSchema = yup.object({
    verificationCode: yup
        .string()
        .trim()
        .required('Required')
        .min(6, 'Enter the full verification code'),
})

type TPhoneProps = {
    setIsLoginSuccess?: Function
}

const PhoneNumber = ({ setIsLoginSuccess }: TPhoneProps) => {
    // Test phone number & code: +1 (345)-678-9101 --> 123456

    const {
        actionText,
        loginType,
        setShowVerificationScreen,
        phoneVerificationText,
    } = useLoginContext()

    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [verificationCode, setVerificationCode] = useState<string>('')
    const [optIn, setOptIn] = useState(false)
    const [inputType, setInputType] = useState<typeof NUMBER | typeof CODE>(
        NUMBER
    )
    const [resentVerification, setResentVerification] = useState<boolean>(false)
    const [loading, setLoading] = useState(false)

    const [phoneNumberFormErrors, setPhoneNumberFormErrors] = useState<
        yup.ValidationError[]
    >([])
    const [verificationCodeFormErrors, setVerificationCodeFormErrors] =
        useState<yup.ValidationError[]>([])

    const clearFormErrorForField = (path: string) => {
        setPhoneNumberFormErrors(
            phoneNumberFormErrors.filter((x) => x.path !== path)
        )

        setVerificationCodeFormErrors(
            verificationCodeFormErrors.filter((x) => x.path !== path)
        )
    }

    useEffect(() => {
        try {
            window.recaptchaVerifier = new RecaptchaVerifier(
                firebaseAuth,
                PHONE_SIGN_IN_BTN_ID,
                {
                    size: 'invisible',
                    callback: () => {
                        return
                    },
                    'expired-callback': () => {
                        console.log('expired captcha')
                    },
                }
            )
        } catch (e: any) {
            if (e?.code === 'auth/argument-error') {
                console.log('no sign in button')
            }
        }
    }, [])

    const handleSignUp = async () => {
        // must be this format: '+1 (345)-678-9101'

        const validNumber = await phoneNumberSchema.isValid(
            { phoneNumber },
            { abortEarly: false }
        )

        if (!validNumber) {
            return phoneNumberSchema
                .validate({ phoneNumber }, { abortEarly: false })
                .then(() => setPhoneNumberFormErrors([]))
                .catch((e) => setPhoneNumberFormErrors(e.inner))
        }

        setLoading(true)

        const formattedPhoneNumber = '+1 ' + phoneNumber.replace(' ', '-')

        const appVerifier: RecaptchaVerifier = window.recaptchaVerifier

        try {
            window.confirmationResult = await signInWithPhoneNumber(
                firebaseAuth,
                formattedPhoneNumber,
                appVerifier
            )

            setInputType(CODE)
            setShowVerificationScreen(true)
            setLoading(false)
        } catch (e: any) {
            if (e?.code === 'auth/captcha-check-failed') {
                const unauthorizedError: yup.ValidationError = {
                    name: 'Unauthorized',
                    path: 'phoneNumber',
                    message: 'Unauthorized domain',
                    value: 'Unauthorized domain',
                    inner: [],
                    errors: [],
                }
                setPhoneNumberFormErrors([unauthorizedError])
            }

            const generalError: yup.ValidationError = {
                name: '',
                path: 'phoneNumber',
                message:
                    'Something went wrong. Please try again later or click Chat Now to get assistance from our GiftGuide.',
                value: 'general error',
                inner: [],
                errors: [],
            }
            setVerificationCodeFormErrors([generalError])
            setLoading(false)
        }
    }

    const confirmCode = async () => {
        const validCode = await verificationCodeSchema.isValid(
            {
                verificationCode,
            },
            { abortEarly: false }
        )

        if (!validCode) {
            return verificationCodeSchema
                .validate({ verificationCode }, { abortEarly: false })
                .then(() => setVerificationCodeFormErrors([]))
                .catch((e) => setVerificationCodeFormErrors(e.inner))
        }

        setLoading(true)

        try {
            await window.confirmationResult.confirm(verificationCode)
            setIsLoginSuccess && setIsLoginSuccess(true)
        } catch (e: any) {
            if (e?.code === 'auth/invalid-verification-code') {
                const invalidCodeError: yup.ValidationError = {
                    name: 'Invalid Verification Code',
                    path: 'verificationCode',
                    message: 'Invalid verification code',
                    value: 'Invalid verification code',
                    inner: [],
                    errors: [],
                }
                setVerificationCodeFormErrors([invalidCodeError])
            }

            if (e?.code === 'auth/too-many-requests') {
                const invalidError: yup.ValidationError = {
                    name: '',
                    path: 'verificationCode',
                    message:
                        'Too many failed attempts. Please try again later.',
                    value: 'too many failed attempts',
                    inner: [],
                    errors: [],
                }
                setVerificationCodeFormErrors([invalidError])
            }

            const generalError: yup.ValidationError = {
                name: '',
                path: 'verificationCode',
                message:
                    'Something went wrong. Please try again later or click Chat Now to get assistance from our GiftGuide.',
                value: 'general error',
                inner: [],
                errors: [],
            }
            setVerificationCodeFormErrors([generalError])
        }

        setLoading(false)
    }

    const resendVerificationCode = async () => {
        if (phoneNumber) {
            await handleSignUp()
            setResentVerification(true)
        } else {
            setInputType(NUMBER)
            setShowVerificationScreen(false)
        }
    }

    return (
        <>
            {inputType === NUMBER ? (
                <>
                    <TextInputWithLabel
                        id={'phone-number'}
                        label={'Phone Number'}
                        numFormat={'(###) ###-####'}
                        value={phoneNumber}
                        onChange={(e: BaseSyntheticEvent) => {
                            let trimmedValue = e.currentTarget.value.trim()
                            setPhoneNumber(trimmedValue)
                            clearFormErrorForField('phoneNumber')
                        }}
                        hasError={inputHasError(
                            phoneNumberFormErrors,
                            'phoneNumber'
                        )}
                    />
                    <FormErrorDisplay
                        formErrors={phoneNumberFormErrors}
                        path={'phoneNumber'}
                        className={'error'}
                    />

                    {loginType === NEW_LOGIN && (
                        <div
                            style={{
                                display: 'flex',
                                gap: '1rem',
                                margin: '2rem auto',
                                fontSize: '14px',
                            }}
                        >
                            <input
                                id="opt-in"
                                name="opt-in"
                                type="checkbox"
                                checked={optIn}
                                onChange={(e: BaseSyntheticEvent) =>
                                    setOptIn(e.target.checked)
                                }
                            />

                            <label htmlFor="opt-in">
                                I would like to receive gift updates from PG
                                eGift Texts at the phone number provided.
                                Message frequency may vary. Message and data
                                rates may apply. Reply HELP for help or STOP to
                                cancel. Contact{' '}
                                <a
                                    href={'mailto: support@perfectgift.com'}
                                    style={{ color: 'var(--colors-secondary' }}
                                >
                                    support@perfectgift.com
                                </a>{' '}
                                via email or click Chat Now on{' '}
                                <a
                                    href={'https://www.perfectgift.com'}
                                    style={{ color: 'var(--colors-secondary' }}
                                >
                                    our website
                                </a>{' '}
                                for help.{' '}
                                <a
                                    href={
                                        'https://www.perfectgift.com/terms-of-use'
                                    }
                                    target="_open"
                                    style={{ color: 'var(--colors-primary' }}
                                >
                                    Terms of Service
                                </a>{' '}
                                and{' '}
                                <a
                                    href={
                                        'https://www.perfectgift.com/privacy-policy'
                                    }
                                    target="_open"
                                    style={{ color: 'var(--colors-primary' }}
                                >
                                    Privacy Policy
                                </a>{' '}
                            </label>
                        </div>
                    )}

                    <Button
                        id={PHONE_SIGN_IN_BTN_ID}
                        label={actionText}
                        onClick={handleSignUp}
                        width="full"
                        showArrow={true}
                        css={{ margin: '36px auto' }}
                        disabled={loading}
                    />
                </>
            ) : (
                <>
                    <p>
                        A verification code has been sent to {phoneNumber}.
                        Please check your inbox and enter the verification code
                        below.
                    </p>

                    {/*
                        <AuthCode> component takes CSS classnames to customize the
                        container and inputs. The classes are declared in the global css file.
                    */}
                    <AuthCode
                        onChange={(val: string) => {
                            setVerificationCode(val.trim())
                            clearFormErrorForField('verificationCode')
                        }}
                        containerClassName="authcode-container"
                        inputClassName="authcode-input"
                    />

                    <FormErrorDisplay
                        formErrors={verificationCodeFormErrors}
                        path={'verificationCode'}
                    />

                    <Button
                        label={phoneVerificationText}
                        onClick={confirmCode}
                        width="full"
                        showArrow={true}
                        textTransform="uppercase"
                        css={{ margin: '36px auto' }}
                        disabled={loading || verificationCode.length < 6}
                    />
                </>
            )}

            {inputType === NUMBER ? (
                ''
            ) : (
                <>
                    {!resentVerification ? (
                        <StyledH6>
                            {"Didn't Receive A Code? "}
                            <button onClick={resendVerificationCode}>
                                Resend Code
                            </button>
                        </StyledH6>
                    ) : (
                        <h4
                            style={{ textAlign: 'center', margin: '40px auto' }}
                        >
                            A new verification code has been sent!
                        </h4>
                    )}
                    <br />
                    <div
                        style={{
                            display: 'flex',
                            gap: '1rem',
                            margin: '1.5rem auto',
                            fontSize: '14px',
                        }}
                    >
                        <p>
                            PerfectGift wants your gift experience to be as safe
                            and secure as possible. To facilitate that, we
                            require an account to be signed into or created to
                            ensure you are the only person accessing your gift
                            and gift details. Please see our{' '}
                            <a
                                href={
                                    'https://www.perfectgift.com/terms-of-use'
                                }
                                target="_open"
                                style={{ color: 'var(--colors-primary' }}
                            >
                                Terms of Service
                            </a>{' '}
                            and{' '}
                            <a
                                href={
                                    'https://www.perfectgift.com/privacy-policy'
                                }
                                target="_open"
                                style={{ color: 'var(--colors-primary' }}
                            >
                                Privacy Policy
                            </a>{' '}
                            for more details about our policies.
                        </p>
                    </div>
                </>
            )}
        </>
    )
}

export default PhoneNumber
